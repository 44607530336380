import axios from "axios";
import { MAPBOX_API } from "src/config-global";
import { Buffer } from "buffer";

const getStaticMapBoxImage = async (
    latitude,
    longitude,
    zoom = 12,
    width = 200,
    height = 200,
    color = "ff0000",
) => {
    const MAPBOX_ACCESS_TOKEN = MAPBOX_API;

    const mapboxUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s+${color}(${longitude},${latitude})/${longitude},${latitude},${zoom}/${width}x${height}?access_token=${MAPBOX_ACCESS_TOKEN}`;

    try {
        const response = await axios.get(mapboxUrl, {
            responseType: "arraybuffer",
        });

        const imageBase64 = Buffer.from(response.data, "binary").toString(
            "base64",
        );

        return `data:image/png;base64,${imageBase64}`;
    } catch (error) {
        console.error("Error fetching the map image:", error);
        return ""
    }
};

export {
    getStaticMapBoxImage,
}