import CustomPopover, { usePopover } from "src/components/custom-popover";

import IconButton from "@mui/material/IconButton";
import Iconify from "src/components/iconify";
import MenuItem from "@mui/material/MenuItem";
import { m } from "framer-motion";
import { useCallback } from "react";
import { useLocales } from "src/locales";
import { varHover } from "src/components/animate";
import { Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { usePathname, useRouter } from "src/routes/hooks";
import { useSnackbar } from "src/components/snackbar";

// HDY: ATENCIÓN, SE COMENTA LA FUNCIONALIDAD DE MULT IDIOMA PARA LA APLICACIÓN DE DEMO PRODUCTIVA
export default function LanguagePopover() {
    const { allLangs, currentLang, t, onChangeLang } = useLocales();
    const popover = usePopover();
    const [searchParams] = useSearchParams();
    const { replace } = useRouter();
    const pathname = usePathname();
    const { enqueueSnackbar } = useSnackbar();

    const handleChangeLang = useCallback(
        async (newLang) => {
            const params = new URLSearchParams(searchParams);
            if (params.size > 0) {
                if (params.has("lang")) {
                    params.delete("lang");
                    replace(`${pathname}?${params.toString()}`);
                }
            }
            const change = onChangeLang(newLang);
            popover.onClose();
            const result = await change;
            if (!result) {
                enqueueSnackbar(t("Something went wrong changing language"), {
                    variant: "error",
                });
            }
        },
        [pathname, popover.onClose, searchParams, onChangeLang, replace],
    );

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                onClick={popover.onOpen}
                sx={{
                    // width: 40,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    height: 40,
                    borderRadius: 1,
                    ...(popover.open && {
                        bgcolor: "action.selected",
                    }),
                }}
            >
                <Typography color="white" sx={{ textTransform: "uppercase" }}>
                    {currentLang.value}
                </Typography>
                <Iconify
                    icon={currentLang.icon}
                    sx={{ borderRadius: 0.65, width: 28 }}
                />
            </IconButton>

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                sx={{ width: 160 }}
            >
                {allLangs.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === currentLang.value}
                        onClick={() => handleChangeLang(option.value)}
                    >
                        <Iconify
                            icon={option.icon}
                            sx={{ borderRadius: 0.65, width: 28 }}
                        />

                        {option.label}
                    </MenuItem>
                ))}
            </CustomPopover>
        </>
    );
}
