import { axiosInstance } from "src/config/axiosInstance";

class TravelPoliciesService {
    static async fetchTravelPolicyDetails(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/travelPolicies/travelPolicy/${projectId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async deleteTravelPolicyCompany(travelPolicyId) {
        try {
            const response = await axiosInstance.delete(
                `/travelPolicies/delete/${travelPolicyId}`,
            );

            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async createTravelPolicy(data) {
        console.log("data sent", data);
        try {
            const response = await axiosInstance.post(
                `/travelPolicies/new`,
                data,
            );

            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // New method to update an existing travel policy
    static async updateTravelPolicy(travelPolicyId, data) {
        console.log("data to update", data);
        try {
            const response = await axiosInstance.put(
                `/travelPolicies/updateTravelPolicy/${travelPolicyId}`,
                data,
            );

            return response;
        } catch (error) {
            console.error("Error updating travel policy:", error);
            throw error;
        }
    }

    static async getTravelPolicyForCrossProject(crossProjectId){
        try{

            const response = await axiosInstance.get(
                `/travelPolicies/crossProject/${crossProjectId}`,
            );

            return response.data;

        }catch(error){
            console.error("Error fetching travel policy for cross project", error);
        }
    }

    // New method to retrieve travel policies for a specific company
    static async fetchTravelPoliciesByCompany(companyId) {
        try {
            const response = await axiosInstance.get(
                `/travelPolicies/company/${companyId}`, // Adjust this endpoint according to your API structure
            );
            return response.data; // Return only the data
        } catch (error) {
            console.error("Error fetching travel policies for company:", error);
            throw error; // Propagate the error
        }
    }   
    
    static async getTravelPolicyForEmployee(employeeId) {
        try {
            const response = await axiosInstance.get(
                `/travelPolicies/employee/${employeeId}`,
            );

            return response.data;
        } catch (error) {
            console.error("Error fetching travel policy for employee:", error);
            throw error;
        }
    }
}

export default TravelPoliciesService;
