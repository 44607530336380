import PropTypes from "prop-types";
// @mui
import Button from "@mui/material/Button";
// routes
import { RouterLink } from "src/routes/components";
// config
import { PATH_AFTER_LOGIN } from "src/config-global";
import { useLocales } from "src/locales";
// ----------------------------------------------------------------------

export default function LoginButton({ sx }) {
    const { t } = useLocales();
    return (
        <Button
            component={RouterLink}
            href={PATH_AFTER_LOGIN}
            variant="outlined"
            sx={{ mr: 1, ...sx }}
        >
            {t("Login")}
        </Button>
    );
}

LoginButton.propTypes = {
    sx: PropTypes.object,
};
