import axiosInstance from "src/config/axiosInstance";

export const fetchGoogleInfo = async (hotelData, currentLang) => {
    try {
        let googleReviews;
        let hotelGooglePlacesInfo;
        if (!hotelData.googlePlacesInfo?.place_id) {
            const res = await axiosInstance.get(
                `/hotels/googlePlacesInfo/${hotelData.gdsprovider}/${hotelData.code}/${currentLang.value}`,
            );
            googleReviews = res.data.googlePlacesInfo?.reviews?.find(
                (reviewObj) => reviewObj.language === currentLang.value,
            );
            hotelGooglePlacesInfo = res.data.googlePlacesInfo;
        } else {
            googleReviews = hotelData.googlePlacesInfo?.reviews?.find(
                (reviewObj) => reviewObj.language === currentLang.value,
            );
            if (!googleReviews) {
                const { data } = await axiosInstance.get(
                    `/hotels/googleReviews/${hotelData.gdsprovider}/${hotelData.code}/${currentLang.value}`,
                );
                hotelGooglePlacesInfo = data.googlePlacesInfo;
                googleReviews = data.googlePlacesInfo.reviews?.find(
                    (reviewObj) => reviewObj.language === currentLang.value,
                );
            } else {
                hotelGooglePlacesInfo = hotelData?.googlePlacesInfo;
            }
        }

        googleReviews =
            googleReviews?.reviews?.reviews || googleReviews?.reviews || [];

        return { googleReviews, hotelGooglePlacesInfo };
    } catch (error) {
        return [];
    }
};
