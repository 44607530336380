import { DateTime } from "luxon";

/**
 *
 * @param {string} isoDate - Date in format ISO. Ex. "2025-02-07T05:51:00"
 * @param {string} timeZone - Timezone. Ex. "Europe/Madrid"
 * @returns {Date}
 */
export const parseDateByTimezone = (isoDate, timeZone) => {
    return DateTime.fromISO(isoDate, { zone: timeZone }).toJSDate();
};
