const preProcessData = (data) => {
    if (data !== undefined && data !== null) {
        return data
            .toString() // Convierte el dato a string
            .replace(/"/g, '""') // Escapa comillas dobles dentro de los campos de datos
            .replace(/\n/g, " ") // Reemplaza saltos de línea con espacios
            .replace(/,/g, ";"); // Opcional: reemplaza comas en los datos con otro caracter para evitar conflictos
    }
    return ""; // Devuelve una cadena vacía para datos undefined o null
};

export const exportToCSV = (columns, rows, fileName) => {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Filtrar la columna 'actions' de los encabezados
    const filteredColumns = columns
        .filter((column) => column.id && column.id !== "action")
        .map((column) => ({
            id: column.id,
            label: column.label || column.id,
        }));
    console.log("filteredColumns", filteredColumns);
    // Agregar la fila de encabezados, excluyendo 'actions'
    const headers = filteredColumns
        .map((column) => `"${preProcessData(column.label)}"`) // Utilizar preProcessData para los encabezados
        .join(",");
    csvContent += headers + "\r\n";

    // Asumiendo que `rows` es tu array de datos
    rows.forEach((row) => {
        const rowData = filteredColumns
            .map((column) => {
                // Split the column's id to handle nested properties (e.g., 'company?.name')
                const props = column.id?.split(/[[\].]+/).filter(Boolean);

                // Reduce the properties to get the correct value from the row
                const value = props?.reduce((nested, key) => {
                    // Check if nested is an object, and key exists
                    if (nested && key in nested) {
                        return nested[key];
                    }
                    return undefined; // Return undefined if the key doesn't exist
                }, row);

                if (typeof value === "object") {
                    return `"${preProcessData(value?.name)}"`;
                }

                // If the value is undefined, return an empty string
                if (value === undefined) return "";

                // Process the value (for escaping special characters, if needed)
                return `"${preProcessData(value)}"`;
            })
            .join(",");

        // Add the row data to the CSV content
        csvContent += rowData + "\r\n";
    });

    // Crear enlace para la descarga
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    link.style.display = "none"; // Ocultar el enlace, necesario para FF
    document.body.appendChild(link);

    link.click(); // Simula un click para iniciar la descarga
    document.body.removeChild(link); // Limpia agregando y luego eliminando el enlace del DOM
};
