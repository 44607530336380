// @mui
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// routes
import { paths } from "src/routes/paths";
import { usePathname } from "src/routes/hooks";
import { RouterLink } from "src/routes/components";
// _mock
import { _socials } from "src/_mock";
// components
import Logo from "src/components/logo";
import Iconify from "src/components/iconify";
import { useLocales } from "src/locales";
// ----------------------------------------------------------------------

const LINKS = [
    {
        headline: "TRAVELANDZ",
        children: [
            { name: "Home", href: "/" },
            { name: "About us", href: paths.about },
            { name: "Contact us", href: paths.contact },
            { name: "Pricing", href: paths.pricing },
            {
                name: "Travel Software for Travel Agencies",
                href: "https://travelandz.com",
            },

            // { name: 'FAQs', href: paths.faqs },
        ],
    },
    {
        headline: "Legal",
        children: [
            { name: "Terms and Conditions", href: "/terms" },
            { name: "Privacy Policy", href: "/privacy" },
        ],
    },
    {
        headline: "Contact",
        children: [
            { name: "hello@travelandz.com", href: paths.contact },
            { name: "AI Travel Software Team", href: paths.about },
        ],
    },
];

// ----------------------------------------------------------------------

export default function Footer() {
    const pathname = usePathname();
    const { t } = useLocales();

    const mainFooter = (
        <Box
            component="footer"
            sx={{
                position: "relative",
                bgcolor: "background.default",
            }}
        >
            <Divider />

            <Container
                sx={{
                    pt: 10,
                    pb: 5,
                    textAlign: { xs: "center", md: "unset" },
                }}
            >
                <Logo
                    src="/assets/logotz.png"
                    sx={{ mb: 5, mx: "auto", width: "14%" }}
                />

                <Grid
                    container
                    justifyContent={{
                        xs: "center",
                        md: "space-between",
                    }}
                >
                    <Grid xs={8} md={3}>
                        <Typography
                            variant="overline"
                            sx={{ color: "text.secondary" }}
                        >
                            {t(
                                "Join Travelandz and Revolutionize Your Travel Experience with AI-Powered Solutions",
                            )}
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{
                                maxWidth: 270,
                                mx: { xs: "auto", md: "unset" },
                            }}
                        >
                            {t(
                                "At Travelandz, we’re transforming the travel industry with cutting-edge AI software designed to enhance the travel experience for small and medium-sized enterprises (SMEs). Our intelligent solutions streamline travel management, optimize costs, and provide personalized options to empower travelers and businesses alike. Together, we can make every journey more efficient and impactful, ensuring your travel needs are met with innovation and care.",
                            )}
                        </Typography>

                        <Stack
                            direction="row"
                            justifyContent={{ xs: "center", md: "flex-start" }}
                            sx={{
                                mt: 3,
                                mb: { xs: 5, md: 0 },
                            }}
                        >
                            {_socials.map((social) => (
                                <a
                                    key={social.name}
                                    href={social.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IconButton
                                        sx={{
                                            "&:hover": {
                                                bgcolor: alpha(
                                                    social.color,
                                                    0.08,
                                                ),
                                            },
                                        }}
                                    >
                                        <Iconify
                                            color={social.color}
                                            icon={social.icon}
                                        />
                                    </IconButton>
                                </a>
                            ))}
                        </Stack>
                    </Grid>

                    <Grid xs={12} md={6}>
                        <Stack
                            spacing={5}
                            direction={{ xs: "column", md: "row" }}
                        >
                            {LINKS.map((list) => (
                                <Stack
                                    key={list.headline}
                                    spacing={2}
                                    alignItems={{
                                        xs: "center",
                                        md: "flex-start",
                                    }}
                                    sx={{ width: 1 }}
                                >
                                    <Typography
                                        component="div"
                                        variant="overline"
                                    >
                                        {t(list.headline)}
                                    </Typography>

                                    {list.children.map((link) => (
                                        <Link
                                            key={link.name}
                                            component={RouterLink}
                                            href={link.href}
                                            color="inherit"
                                            variant="body2"
                                        >
                                            {t(link.name)}
                                        </Link>
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>

                <Typography variant="body2" sx={{ mt: 10 }}>
                    © 2025 All rights reserved by Travelandz
                </Typography>
            </Container>
        </Box>
    );

    return mainFooter;
}
