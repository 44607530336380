import * as XLSX from "xlsx";

export const exportToExcel = (rows, columns, fileName = "Data.xlsx") => {
    // Extraer los identificadores y etiquetas de las columnas, excluyendo 'action'
    const filteredColumns = columns
        .filter((column) => column.id && column.id !== "action")
        .map((column) => ({
            id: column.id,
            label: column.label || column.id,
        }));

    // Convertir los datos de las filas en un formato compatible, asegurando incluir los encabezados
    const data = rows.map((row) =>
        filteredColumns.reduce((acc, { id, label }) => {
            const props = id?.split(/[[\].]+/).filter(Boolean);
            const value = props?.reduce((nested, key) => nested?.[key], row);
            acc[label] = value;

            if (typeof value === "object") {
                acc[label] = value?.name;
            }

            return acc;
        }, {}),
    );

    // Si 'rows' está vacío, asegurarse de que al menos se generen los encabezados
    if (data.length === 0) {
        const headers = filteredColumns.reduce((acc, column) => {
            acc[column.label] = "";
            return acc;
        }, {});
        data.push(headers);
    }
    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, fileName);
};
