import { useCallback, useEffect, useRef, useState } from "react";
import { axiosInstance } from "src/config/axiosInstance";
import { useAuthContext } from "src/auth/hooks";
import { endpoints } from "src/utils/axios";
import { ExchangeRateContext } from "./context";

const SPECIAL_TOKEN = process.env.REACT_APP_TZ_API_KEY;
const TIME_LAPSE = 600000; // 10 minutes

export function ExchangeRateProvider({ children }) {
    const timeRef = useRef(null);
    const { user } = useAuthContext();
    const [exchangeRate, setExchangeRate] = useState({
        currency: "EUR",
        rate: 1,
    });
    const updateExchangeRate = useCallback(
        async (projectId, forceUpdate = false) => {
            try {
                const accessToken = user?.session?.token || user?.token;
                const userId = user?._id;
                if (!projectId && (!userId || !accessToken)) {
                    return;
                }

                const now = Date.now();
                if (
                    !forceUpdate &&
                    timeRef.current &&
                    now - timeRef.current < TIME_LAPSE
                ) {
                    // The function was executed less than 10 minutes ago
                    return;
                }
                timeRef.current = now;
                let requestBody;
                let requestConfig = {};
                if (userId) {
                    requestBody = {
                        accessToken,
                        userId,
                    };
                } else {
                    requestBody = {
                        projectId,
                    };
                    requestConfig = {
                        headers: { "tz-api-key": SPECIAL_TOKEN },
                    };
                }
                const response = await axiosInstance.post(
                    endpoints.auth.exchangeRate,
                    requestBody,
                    requestConfig,
                );
                setExchangeRate(response.data);
            } catch (error) {
                console.log(error);
                timeRef.current = null;
            }
        },
        [user],
    );

    useEffect(() => {
        if (!user) return;
        updateExchangeRate();
    }, [updateExchangeRate, user]);

    return (
        <ExchangeRateContext.Provider
            value={{
                exchangeRate,
                setExchangeRate,
                updateExchangeRate,
            }}
        >
            {children}
        </ExchangeRateContext.Provider>
    );
}
